import * as THREE from 'three'

export default class InformationSection {
    constructor(_options) {
        // Options
        this.time = _options.time
        this.resources = _options.resources
        this.objects = _options.objects
        this.areas = _options.areas
        this.tiles = _options.tiles
        this.debug = _options.debug
        this.x = _options.x
        this.y = _options.y

        // Set up
        this.container = new THREE.Object3D()
        this.container.matrixAutoUpdate = false

        this.setStatic()
        this.setBaguettes()
        this.setLinks()
        this.setActivities()
        this.setTiles()
    }

    setStatic() {
        this.objects.add({
            base: this.resources.items.informationStaticBase.scene,
            collision: this.resources.items.informationStaticCollision.scene,
            floorShadowTexture: this.resources.items.informationStaticFloorShadowTexture,
            offset: new THREE.Vector3(this.x, this.y, 0),
            mass: 0
        })
    }

    setBaguettes() {
        this.baguettes = {}

        this.baguettes.x = - 4
        this.baguettes.y = 6

        this.baguettes.a = this.objects.add({
            base: this.resources.items.informationBaguetteBase.scene,
            collision: this.resources.items.informationBaguetteCollision.scene,
            offset: new THREE.Vector3(this.x + this.baguettes.x - 2, this.y + this.baguettes.y, 0.1),
            rotation: new THREE.Euler(0, 0, - Math.PI * 37 / 180),
            duplicated: true,
            shadow: { sizeX: 0.6, sizeY: 3.5, offsetZ: - 0.15, alpha: 0.35 },
            mass: 1.5,
            // soundName: 'woodHit'
        })

        this.baguettes.b = this.objects.add({
            base: this.resources.items.informationBaguetteBase.scene,
            collision: this.resources.items.informationBaguetteCollision.scene,
            offset: new THREE.Vector3(this.x + this.baguettes.x - 3, this.y + this.baguettes.y - 0.5, 0.5),
            rotation: new THREE.Euler(0, - 0.5, Math.PI * 60 / 180),
            duplicated: true,
            shadow: { sizeX: 0.6, sizeY: 3.5, offsetZ: - 0.15, alpha: 0.35 },
            mass: 1.5,
            sleep: false,
            // soundName: 'woodHit'
        })
    }

    setLinks() {
        // Set up
        this.links = {}
        this.links.x = 1.95
        this.links.y = - 1.5
        this.links.halfExtents = {}
        this.links.halfExtents.x = 1
        this.links.halfExtents.y = 1
        this.links.distanceBetween = 2.4
        this.links.labelWidth = this.links.halfExtents.x * 2 + 1
        this.links.labelGeometry = new THREE.PlaneBufferGeometry(this.links.labelWidth, this.links.labelWidth * 0.25, 1, 1)
        this.links.labelOffset = - 1.6
        this.links.items = []

        this.links.container = new THREE.Object3D()
        this.links.container.matrixAutoUpdate = false
        this.container.add(this.links.container)

        // Options
        this.links.options = [

            {
                href: 'https://www.instagram.com/alborz_Institute',
                labelTexture: this.resources.items.informationContactInstagramLabelTexture
            },
            {
                href: 'http://45.138.132.61/software',
                labelTexture: this.resources.items.informationContactYoutubeLabelTexture
            },
            {
                href: 'http://45.138.132.61/software',
                labelTexture: this.resources.items.informationContactTwitterLabelTexture
            },
            {
                href: 'http://45.138.132.61/software',
                labelTexture: this.resources.items.informationContactGithubLabelTexture
            },
            {
                href: 'http://45.138.132.61/software',
                labelTexture: this.resources.items.informationContactLinkedinLabelTexture
            },
            {
                href: 'mailto:info@ins-alborz.com',
                labelTexture: this.resources.items.informationContactMailLabelTexture
            },
            {
                href: 'http://45.138.132.61/software',
                labelTexture: this.resources.items.aboutTexture
            },
            {
                href: 'https://goo.gl/maps/5jQi5h9p54SMTev19',
                labelTexture: this.resources.items.informationContactAddressLabelTexture
            },
            {
                href: 'http://45.138.132.61/software',
                labelTexture: this.resources.items.enamadTexture
            }
          

        ]

        // Create each link
        let i = 0
        for (const _option of this.links.options) {

            // Set up
            const item = {}
            item.x = this.x + 2.8 + this.links.x + this.links.distanceBetween * i
            item.y = this.y + this.links.y
            item.href = _option.href

            if (i < 7  ) {

              if(i==6) {item.x = -2 ;  item.y = -65;   } 
                item.area = this.areas.add({
                    position: new THREE.Vector2(item.x, item.y),
                    halfExtents: new THREE.Vector2(this.links.halfExtents.x, this.links.halfExtents.y)
                })
            }

            if (i == 7) {
                item.x = -4.7
                item.y = -54.2
                item.href = _option.href
                // Create area

                item.area = this.areas.add({
                    position: new THREE.Vector2(item.x, item.y),
                    halfExtents: new THREE.Vector2(this.links.halfExtents.x + 2, this.links.halfExtents.y + 2)
                })
            }


            if (i == 8) {
                item.x = -10
                item.y = -3
                item.href = _option.href
                // Create area

                item.area = this.areas.add({
                    position: new THREE.Vector2(item.x, item.y),
                    halfExtents: new THREE.Vector2(this.links.halfExtents.x + 1, this.links.halfExtents.y)
                })
            }


            




            item.area.on('interact', () => {
                setTimeout(() => { window.open(_option.href, '_blank')}, 1000);
               
            })

            // Texture
            item.texture = _option.labelTexture
            item.texture.magFilter = THREE.NearestFilter
            item.texture.minFilter = THREE.LinearFilter

            // Create label
            if (i < 7 ) {
                item.labelMesh = new THREE.Mesh(this.links.labelGeometry, new THREE.MeshBasicMaterial({ wireframe: false, color: 0xffffff, alphaMap: _option.labelTexture, depthTest: true, depthWrite: false, transparent: true }))
                item.labelMesh.position.x = item.x + this.links.labelWidth * 0.5 - this.links.halfExtents.x
                item.labelMesh.position.y = item.y + this.links.labelOffset

            }

            else {
  
                if (i == 8) {
                    item.labelMesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.links.labelWidth + 0.5, this.links.labelWidth * 0.6, 1, 1), new THREE.MeshBasicMaterial({ wireframe: false, color: 0xffffff, alphaMap: _option.labelTexture, depthTest: true, depthWrite: false, transparent: true }))
                }
                else {
                    item.labelMesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.links.labelWidth + 3, this.links.labelWidth + 3, 1, 1), new THREE.MeshBasicMaterial({ wireframe: false, color: 0xffffff, alphaMap: _option.labelTexture, depthTest: true, depthWrite: false, transparent: true }))
                }
                item.labelMesh.position.x = item.x
                item.labelMesh.position.y = item.y
            }

            item.labelMesh.matrixAutoUpdate = false
            item.labelMesh.updateMatrix()
            this.links.container.add(item.labelMesh)

            // Save
            this.links.items.push(item)

            i++
        }
    }

    setActivities() {
        // Set up
        this.activities = {}
        this.activities.x = this.x + 0
        this.activities.y = this.y - 10
        this.activities.multiplier = 5.5

        // Geometry
        this.activities.geometry = new THREE.PlaneBufferGeometry(2 * this.activities.multiplier, 1 * this.activities.multiplier, 1, 1)

        // Texture
        this.activities.texture = this.resources.items.informationActivitiesTexture
        this.activities.texture.magFilter = THREE.NearestFilter
        this.activities.texture.minFilter = THREE.LinearFilter

        // Material
        this.activities.material = new THREE.MeshBasicMaterial({ wireframe: false, color: 0xffffff, alphaMap: this.activities.texture, transparent: true })

        // Mesh
        this.activities.mesh = new THREE.Mesh(this.activities.geometry, this.activities.material)
        this.activities.mesh.position.x = this.activities.x
        this.activities.mesh.position.y = this.activities.y
        this.activities.mesh.matrixAutoUpdate = false
        this.activities.mesh.updateMatrix()
        this.container.add(this.activities.mesh)
    }

    setTiles() {
        this.tiles.add({
            start: new THREE.Vector2(this.x - 1.2, this.y + 13),
            delta: new THREE.Vector2(0, - 20)
        })
    }
}
